<template>
  <v-card
    v-if="loaded"
    rounded="lg"
    elevation="0"
    class="transparent px-2 py-2"
  >
    <div class="fs-24 color-state font-weight-bold mb-5">
      <span>Amikom English Proficiency Test</span>
    </div>
    <v-card rounded="lg" elevation="0" class="px-2 py-2 mb-2">
      <v-form
        class="px-3"
        @submit.prevent="submit"
        lazy-validation
        ref="form"
        v-model="valid"
      >
        <v-row>
          <v-col cols="" md="6" class="">
            <div class="mb-2">
              <span class="fs-18 font-weight-medium">Kode Lisensi</span>
            </div>
            <v-text-field
              class="mb-2"
              v-model="codeLisensi"
              placeholder="Masukkan kode lisensi"
              required
              outlined
              hide-details="auto"
            ></v-text-field>
            <v-alert type="error" text v-show="response !== null">{{
              response
            }}</v-alert>
          </v-col>
          <v-col cols="" md="6" class="">
            <div class="mb-2">
              <span class="font-weight-medium white--text">Kode Lisensi</span>
            </div>
            <v-btn
              large
              elevation="0"
              type="submit"
              color="primary"
              :loading="submiting"
              class="position-absolute bottom-0"
            >
              Redeem Lisensi
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-expansion-panels v-show="list" class="ticket">
      <v-expansion-panel
        class="mb-3 rounded-md"
        elevation="0"
        v-for="(list, index) in lists"
        :key="index"
      >
        <v-expansion-panel-header class="ma-1">
          <p class="fs-20 color-state font-weight-bold mb-0">
            Amikom English Proficiency Test {{ index + 1 }}
          </p>
          <template v-slot:actions>
            <v-btn
              large
              elevation="0"
              @click.stop="saveStorage(list)"
              :loading="process.run"
              color="primary"
              class="position-absolute bottom-0 mr-3"
            >
              Mulai Test AEPT
            </v-btn>
            <!-- <router-link
              :to="{ name: 'epot-preparation', params: { id: list.id } }"
              class="mr-3 v-btn theme--light elevation-0 v-size--large primary"
              @click.stop="saveStorage(list)"
              >Mulai Test AEPT
            </router-link> -->
            <v-icon> $expand </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="color-disable">
            <p class="mr-3">
              Order ID : {{ list.order_no.replace("YEC", "AEPT") }}
            </p>
            <p>Tanggal Pembelian : {{ currentDateTime(list.created_at) }}</p>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- <v-expansion-panel class="mb-3 rounded-md" elevation="0">
        <v-expansion-panel-header class="ma-1">
          <p class="fs-20 color-state font-weight-bold mb-0">TOEFL-Like</p>
          <template v-slot:actions>
            <v-btn 
              color="primary"
              elevation="0"
              class="mr-3"
              disabled
              large
            >
              Sudah Dikerjakan
            </v-btn>
            <v-icon> $expand </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="color-disable">
            <p class="mr-3">Order ID : 00897AE</p>
            <p>Tanggal Pembelian : 20/10/2020</p>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-show="list" class="mb-3 rounded-md" elevation="0">
        <v-expansion-panel-header class="ma-1">
          <p class="fs-20 color-state font-weight-bold mb-0">TOEFL-Like</p>
          <template v-slot:actions>
            <router-link
              class="mr-3 v-btn theme--light elevation-0 v-size--large primary"
              :to="{ name: 'epot-preparation' }"
              @click.stop
              >Mulai Test AEPT
            </router-link>
            <v-icon> $expand </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="color-disable">
            <p class="mr-3">Order ID : 00897AE</p>
            <p>Tanggal Pembelian : 20/10/2020</p>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel> -->
    </v-expansion-panels>

    <v-dialog persistent v-model="dialog.maintenance">
      <v-img class="mx-auto" :src="dialog.image"> </v-img>
    </v-dialog>

    <v-dialog persistent width="800" v-model="dialog.announcement">
      <v-img class="mx-auto" :src="dialog.image">
        <v-btn
          @click="dialog.announcement = false"
          icon
          style="right: 2%; top: 2%; z-index: 99999; position: absolute"
          color="black"
          class="pa-4"
          text
        >
          <v-icon size="30">mdi-close</v-icon>
        </v-btn>
      </v-img>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "epot-start",
  data() {
    return {
      loaded: true,
      lists: {},
      detailCompany: {},
      dialog: {
        maintenance: false,
        announcement: false,
        image: "",
      },
      process: {
        run: false,
      },
      date: moment(new Date()).format("DD MMMM YYYY"),
      list: true,
      codeLisensi: "",
      errors: [],
      valid: true,
      submiting: false,
      response: null,
      // changeDisable:false
      // idExam:''
    };
  },
  // watch: {
  //   idExam(newId) {
  //     localStorage.idExam = newId;
  //   }
  // }
  components: {},
  created() {
    // let today = new Date();
    // let day = today.getDate();
    // let month = today.getMonth();
    // let year = today.getFullYear();
    // let hours = today.getHours();

    // if (day == '9' && hours >= '22' || day == '10' && hours <= '5') {
    //   this.dialog.maintenance = true
    // }

    // console.log(this.$store.getters.isLoggedIn);
    this.getData();
    // window.history.forward();
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("ytc_lms_auth"));
    },
  },
  mounted() {
    this.DetailCompany();
  },
  methods: {
    async DetailCompany() {
      let user = await JSON.parse(localStorage.getItem("ytc_lms_auth"));
      await this.axios
        .get(`/company/v1/get/company/${user.company.id}`)
        .then((response) => {
          let res = response.data;
          console.log(res);
          if (res.status == 200) {
            this.detailCompany = res.data;
            if (
              !res.data.settings.announcement.is_active &&
              res.data.settings.announcement.images.length == 0
            ) {
              return;
            }
            if (
              !res.data.settings.announcement.is_active &&
              res.data.settings.announcement.images.length > 0
            ) {
              this.dialog.announcement = true;
              this.dialog.image = res.data.settings.announcement.images[0].url;
            }
            if (
              res.data.settings.announcement.is_active &&
              res.data.settings.announcement.images.length > 0
            ) {
              this.dialog.maintenance = true;
              this.dialog.image = res.data.settings.announcement.images[0].url;
            }
          } else {
            console.log(res.message);
          }
        })
        .catch((error) => {
          this.errors.message = error.message;
          this.saving = false;
        });
    },
    async saveStorage(item) {
      this.process.run = true;
      localStorage.setItem("ytc_lms_license", item.license);
      if (this.user.is_b2b == 1) {
        await this.axios
          .get(
            `/institusion/v1/get/institusion/detail/${this.user.institusion.id}`
          )
          .then((response) => {
            let res = response.data;
            localStorage.setItem(
              "ytc_lms_institution",
              JSON.stringify(res.data)
            );
            this.process.run = false;
            this.$router.push({
              name: "epot-preparation",
              params: { id: item.id },
            });
          });
      } else {
        this.$router.push({
          name: "epot-preparation",
          params: { id: item.id },
        });
        localStorage.setItem("ytc_lms_institution", JSON.stringify({}));
      }
    },
    submit() {
      this.submiting = true;
      const sk = this.$refs.form.validate();
      console.log("form", sk);
      let license = this.codeLisensi;
      if (license != "") {
        setTimeout(() => {
          this.axios
            .post(
              `/users/v1/member/order/create/`,
              { license },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              let res = response.data;
              console.log(res);
              this.loading = false;
              if (response.status == 200) {
                this.submiting = false;
                this.list = true;
                this.getData();
                this.$refs.form.reset();
              } else if (response.status == 400) {
                console.log("hasil2", response);
              }
            })
            .catch((e) => {
              this.submiting = false;
              this.$refs.form.reset();
              this.response = e.response.data
                ? e.response.data.message
                : "Kode lisensi yang anda masukan tidak valid";
              setTimeout(() => {
                this.response = null;
              }, 5000);
            });
          // if (lisensi == '12345678') {
          //   this.submiting=false;
          //   this.list=true;
          //   this.getData();
          //   this.$refs.form.reset()
          // }
        }, 1000);
      } else {
        this.submiting = false;
        this.$refs.form.reset();
        this.response = "Kode Lisensi tidak boleh kosong";
        setTimeout(() => {
          this.response = null;
        }, 5000);
      }
    },
    goTest() {
      // if (localStorage.idExam) {
      //   this.idExam = localStorage.idExam;
      // }
      // window.location.reload(true);
    },
    getData() {
      this.loading = true;
      this.axios
        .get(`/users/v1/member/order/list`, {
          params: {
            limit: 20,
          },
        })
        .then((response) => {
          let res = response.data.data;
          this.loading = false;
          if (response.status == 200) {
            this.loading = false;
            this.lists = res.list;
            // console.log(this.lists);
          }
        });
    },
    currentDateTime(a) {
      return moment(a).format("D-MMMM-YYYY");
    },
  },
};
</script>

<style>
.ticket .v-expansion-panel:before {
  /*box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);*/
  box-shadow: none !important;
}
.ticket .v-expansion-panel {
  border-radius: 4px !important;
}
.ticket .v-expansion-panel:not(:first-child):after {
  border-color: rgb(239 242 247) !important;
}
</style>
