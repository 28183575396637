<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <!-- Sidenav / Navigation drawer -->
      <div>
        <v-navigation-drawer
          v-show="show_sidebar"
          height="100vh"
          fixed
          floating
          v-model="drawer"
          :permanent="$vuetify.breakpoint.mdAndUp"
          :temporary="!$vuetify.breakpoint.mdAndUp"
        >
          <div class="mx-auto my-1 text-center">
            <!-- <router-link to="/"> -->
            <img
              src="@/assets/logo/logo_amikom.png"
              alt="Avatar"
              class="img-yec"
            />
            <!-- </router-link> -->
          </div>
          <!-- <v-spacer class="mt-9"></v-spacer> -->
          <v-divider> </v-divider>
          <v-list dense flat>
            <v-list-item-group color="primary">
              <template v-for="item in menus">
                <v-list-item
                  :disabled="
                    !(user || {}).is_updated_profile && item.title != 'Account' && item.title != 'Kebijakan Privasi'
                  "
                  :key="item.link"
                  class="py-1 pl-6 font-weight-medium"
                  active-class="menu-active"
                  :to="item.link"
                  @click="pageChanged(0, item)"
                >
                  <v-list-item-icon class="mr-4 fs-14">
                    <v-icon
                      class="color--text"
                      :class="
                        (user || {}).is_updated_profile ||
                        item.title == 'Account' ||
                        'text--lighten-3'
                      "
                      >{{ item.icon }}</v-icon
                    >
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      class="fs-14 color--text"
                      :class="
                        (user || {}).is_updated_profile ||
                        item.title == 'Account' ||
                        'text--lighten-3'
                      "
                      >{{ item.title }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>

        <!-- Topnav / App bar -->
        <v-main
          :style="{
            marginLeft:
              show_sidebar && $vuetify.breakpoint.mdAndUp ? '256px' : 0,
          }"
        >
          <v-app-bar
            :style="{
              marginLeft:
                show_sidebar && $vuetify.breakpoint.mdAndUp ? '256px' : 0,
            }"
            flat
            fixed
          >
            <v-app-bar-nav-icon
              v-if="!$vuetify.breakpoint.mdAndUp"
              @click="drawer = true"
            ></v-app-bar-nav-icon>

            <v-toolbar-title v-show="!show_sidebar">
              <!-- <h3 class="font-weight-regular">{{ pageTitle }}</h3> -->
              <div class="my-1 text-left">
                <img
                  src="@/assets/logo/logo_amikom.png"
                  alt="Avatar"
                  class="img-yec-test"
                />
                <!-- @click="menu()" -->
              </div>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="ml-6 rounded-pill"
                  v-ripple
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-avatar class="elevation-0 white mr-2" size="40px">
                    <!-- <img src="@/assets/logo.png" alt="Avatar" /> -->
                    <div
                      class="pa-10 bg-grey rounded-circle d-inline-block mb-3 fs-20"
                    >
                      <v-icon>ri-user-line</v-icon>
                    </div>
                  </v-avatar>
                  <span class="font-weight-medium">{{
                    (user || {}).is_updated_profile
                      ? (user || {}).fullname
                      : (user || {}).email
                  }}</span>
                  <v-icon class="mx-2" small>$dropdown</v-icon>
                </div>
              </template>

              <v-list dense>
                <v-list-item link @click="logout">
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-app-bar>

          <v-container fluid style="margin-top: 64px">
            <div class="content-wrapper mx-2">
              <transition name="fade-up">
                <router-view v-if="loaded"></router-view>
              </transition>
            </div>
          </v-container>
        </v-main>
      </div>
    </v-app>
  </transition>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      breadcrumbs: [],
      loaded: true,
      drawer: null,
      notifications: [1],
      pageTitle: "",
      // title:false,
      pathnya: "",
      items: [
        { title: "Test AEPT", icon: "$epot", link: "/epot" },
        // { title: "Sertifikat", icon: "$guide", link: "/reward" },
        { title: "Panduan", icon: "$exercise", link: "/guide" },
        { title: "Account", icon: "$account", link: "/account" },
      ],
    };
  },
  computed: {
    ...mapGetters(["user"]),

    menus() {
      let data_menu = [];

      if ((this.user || {})?.institusion?.id !== "") {
        data_menu = [
          { title: "Test AEPT", icon: "$epot", link: "/epot" },
          { title: "Panduan", icon: "$exercise", link: "/guide" },
          { title: "Account", icon: "$account", link: "/account" },
          { title: "Kebijakan Privasi", icon: "$epot", link: "/privacy-policy" },

        ];
      } else {
        data_menu = [
          { title: "Test AEPT", icon: "$epot", link: "/epot" },
          // { title: "Sertifikat", icon: "$guide", link: "/reward" },
          { title: "Panduan", icon: "$exercise", link: "/guide" },
          { title: "Account", icon: "$account", link: "/account" },
          { title: "Kebijakan Privasi", icon: "$epot", link: "/privacy-policy" },
        ];
      }
      return data_menu;
    },

    show_sidebar() {
      return !/\/epot\/.\.*/.test(this.$route.path);
    },
  },
  created() {
    this.disableRight();
    this.$root.$on("setPageTitle", (pageTitle) => {
      this.pageTitle = pageTitle;
      // console.log(pageTitle);
    });

    // let path = "/" + this.$route.path.split("/")[1];
    this.pathnya = this.$route.path;
    // console.log("pathnya", this.pathnya);
    // let index = this._.findIndex(this.items, { link: path });
    // if (index != -1) {
    //   this.pageTitle = this.items[index].title;
    //   this.breadcrumbs.push({
    //     text: this.items[index].title,
    //     disabled: true,
    //     href: path,
    //   });
    // }
  },
  methods: {
    disableRight() {
      window.addEventListener(
        "contextmenu",
        function (e) {
          // do something here...
          e.preventDefault();
        },
        false
      );
    },
    // logout() {
    //   this.$store.dispatch("logout").then(() => {
    //     window.location.href = 'https://www.yec.co.id/account/toefl/?debug=true';
    //   });
    // },
    logout() {
      this.$store.dispatch("logout").then(() => {
        const a = document.createElement("a");
        a.href = "https://potensa.amikom.ac.id/";
        a.click();
        // this.$router.push("/");
      });
    },
    menu() {
      this.$router.push("/");
    },
    updateLocation(item) {
      // this.pageChanged(0, item);
      this.pageTitle = item.title;
    },
    pageChanged(segment, location = null) {
      // console.log(segment);
      // console.log(location);
      this.pathnya = this.$route.path;
      // console.log("pathpage", this.pathnya);
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }
      // window.location.reload(true);
      document.title =
        this.breadcrumbs.map((val) => val.text).join(" -> ") +
        " - " +
        process.env.VUE_APP_TITLE;
    },
  },
};
</script>

<style>
.v-toolbar__content {
  background: #fff;
}
.v-main__wrap {
  background: #eff2f7;
}
.content-wrapper {
  position: relative;
}
.menu-active::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  left: 0;
  background: var(--v-primary-base);
}
.v-application .menu-active .color--text {
  color: var(--primary-text) !important;
}
.img-yec {
  height: 50px;
  width: 100%;
  object-fit: contain;
}
/*.img-yec:hover {
  cursor: pointer;
}*/
.img-yec-test {
  height: 50px;
  object-fit: contain;
}
/*.img-yec-test:hover {
  cursor: pointer;
}*/
</style>